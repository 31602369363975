<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h2 class="overline mt-2 ml-4 grey--text d-flex">Engagements</h2>
        </div>
        <v-select
          class="mr-2 pl-4 pr-4"
          label="Campaña"
          v-model="selectedCampaign"
          :items="selectableCampaigns"
          item-text="displayName"
          item-value="id"
          @click="$store.dispatch('clearCampaignEngagements')"
          @change="$store.dispatch('getCampaignEngagements', selectedCampaign)"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="campaignEngagements"
          dense
          :headers="vDataTableHeaders"
          :items="campaignEngagements.items"
          @click:row="handleRowClick"
        ></v-data-table>

        <div class="d-flex ml-4 mr-4 mt-4">
          <v-select
            class="mr-2"
            label="Campaña"
            outlined
            dense
            v-model="selectedCampaign"
            readonly
            filled
            :items="selectableCampaigns"
            item-text="displayName"
            item-value="id"
            return-object
          >
          </v-select>

          <v-select
            label="Establecimiento"
            class="mr-2"
            outlined
            dense
            v-model="newEngagement.establishment"
            :items="establishments"
            item-text="name"
            item-value="id"
          >
          </v-select>

          <v-select
            label="Estado de materialización"
            v-model="newEngagement.materializationStatus"
            class="mr-2"
            outlined
            dense
            :value="{ value: 1, name: '1' }"
            :items="[
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              { value: 4, name: '4' },
            ]"
            item-text="name"
            item-value="id"
          >
          </v-select>

          <v-text-field
            v-model.number="newEngagement.rentQqHa"
            type="number"
            dense
            outlined
            label="Alquiler (Qq/Ha)"
          ></v-text-field>

          <v-btn
            color="blue white--text"
            class="ml-2"
            @click="saveNewEngagement"
            depressed
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import useSelectable from "../mixins/useSelectables";
import useSaveNewEngagement from "../mixins/useSaveNewEngagement";

export default {
  //
  name: "Engagements",
  mixins: [useSelectable, useSaveNewEngagement],
  computed: {
    ...mapGetters(["campaignEngagements", "establishments"]),
    vDataTableHeaders() {
      return [{name: "Campaña", value:"campaignName"}, {name: 'Version', value:"campaignVersion"}, {name: 'Establecimiento', value:"establishmentName"}].map(
        (i) => {
          return {
            text: i.name.toLocaleUpperCase()
              ,
            value: i.value,
          };
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("getAbstractCampaigns");
    this.$store.dispatch("getCampaigns");
    this.$store.dispatch("getEstablishments");
  },
  methods: {
    handleRowClick(e) {
      this.$router.push({
        path: `/engagement/${e.engagementId}`,
      });
    },
  },
  data() {
    return {
      selectedCampaign: null,
      newEngagement: {
        establishment: {},
        rentQqHa: null,
        materializationStatus: 1,
      },
    };
  },
};
</script>
