import { postRequest } from "@/managrx/serverCall";

let useSaveNewEngagement = {
  methods: {
    async saveNewEngagement() {
      let payload = {
        campaignId: this.selectedCampaign,
        establishmentId: this.newEngagement.establishment,
        materializationStatus: this.newEngagement.materializationStatus,
        rentQqHa: this.newEngagement.rentQqHa,
        rentQqActivity: 18, // SOJA DE PRIMERA
      };

      try {
        let response = await postRequest("/engagement", payload);
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          console.log("saveNewEngagement Response");
          console.log(response.data);
          let id = response.data;
          this.$router.push("/engagement/" + id);
        }
      } catch (error) {
        console.log(error);
        let notif = { type: "error", text: `${error?.response?.data?.error}` };
        this.$store.dispatch("setNewNotification", notif);
      }

      this.$store.dispatch("getCampaignEngagements", this.selectedCampaign);
      this.$store.dispatch("getEstablishments");
    },
  },
};

export default useSaveNewEngagement;
